var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "phone-charge--flex",
            staticStyle: { padding: "10px", "background-color": "#f5f5f5" }
          },
          [
            _c("span", { staticStyle: { color: "#F62817" } }, [
              _vm._v(_vm._s(_vm.tableData.update_count) + "个")
            ]),
            _vm._v("供应商SKU的上游成本价变动了，请及时查看处理！ ")
          ]
        ),
        _c(
          "div",
          { staticClass: "search-term search-price-info" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form-inline",
                attrs: {
                  inline: true,
                  model: _vm.searchInfo,
                  "label-width": "150px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "选择系统:", "label-width": "100px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: {
                          filterable: "",
                          placeholder: "请选择商品状态"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onSubmit(3)
                          }
                        },
                        model: {
                          value: _vm.searchInfo.resource_version,
                          callback: function($$v) {
                            _vm.$set(_vm.searchInfo, "resource_version", $$v)
                          },
                          expression: "searchInfo.resource_version"
                        }
                      },
                      _vm._l(_vm.versionOption, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商SKU ID:" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入供应商SKU ID",
                        onkeyup: "value=value.replace(/[^\\d]/g,'')"
                      },
                      model: {
                        value: _vm.searchInfo.sku_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "sku_id", _vm._n($$v))
                        },
                        expression: "searchInfo.sku_id"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商SKU 名称:" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入供应商SKU 名称" },
                      model: {
                        value: _vm.searchInfo.sku_keyword,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "sku_keyword", $$v)
                        },
                        expression: "searchInfo.sku_keyword"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商价格变动状态:" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "150px" },
                        attrs: {
                          filterable: "",
                          placeholder: "请选择商品状态"
                        },
                        model: {
                          value: _vm.searchInfo.update_price_status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchInfo, "update_price_status", $$v)
                          },
                          expression: "searchInfo.update_price_status"
                        }
                      },
                      _vm._l(_vm.statusOption, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        (_vm.searchInfo.resource_version === 1
                          ? "商品ID"
                          : "SPU ID") + "：",
                      "label-width": "100px"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入 ID",
                        onkeyup: "value=value.replace(/[^\\d]/g,'')"
                      },
                      model: {
                        value: _vm.searchInfo.spu_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "spu_id", _vm._n($$v))
                        },
                        expression: "searchInfo.spu_id"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        (_vm.searchInfo.resource_version === 1
                          ? "商品名称"
                          : "SPU 名称") + "：",
                      "label-width": "100px"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入 名称" },
                      model: {
                        value: _vm.searchInfo.spu_keyword,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "spu_keyword", $$v)
                        },
                        expression: "searchInfo.spu_keyword"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "更新时间:", "label-width": "100px" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "80%" },
                      attrs: {
                        format: "yyyy-MM-dd HH:mm:ss",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.updateTime,
                        callback: function($$v) {
                          _vm.updateTime = $$v
                        },
                        expression: "updateTime"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("查 询")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-button", { on: { click: _vm.onReset } }, [
                      _vm._v("重 置")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.exportPrice }
                      },
                      [_vm._v("导出报价单")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.searchInfo.resource_version === 1
        ? _c("old-resource", {
            attrs: { content: _vm.oldList },
            on: { getTableData: _vm.getTableData }
          })
        : _c("new-resource", {
            attrs: { content: _vm.newList },
            on: { getTableData: _vm.getTableData }
          }),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }